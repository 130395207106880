import React from 'react';
import './App.css';
import { FaInstagram, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      <div className="construction-container">
        <img
          src={require('./logo.png')}
          alt="Logo do Site"
          className="logo"
        />
        <h1>Nosso site está em construção</h1>
        <p>Em breve traremos novidades para você!</p>

        <div className="social-icons">
          <div className="icon-item">
            <a href="https://instagram.com/polonortetransportes" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
            <span>Instagram</span>
          </div>
          <div className="icon-item">
            <a href="https://wa.me/+5585999406359" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={30} />
            </a>
            <span>WhatsApp</span>
          </div>
          <div className="icon-item">
            <a href="https://maps.app.goo.gl/cnd9wqTmr9qAd3hy7" target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt size={30} />
            </a>
            <span>Localização</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
